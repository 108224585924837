.app__contact {
    flex: 1;
    width: 100%;
    flex-direction: column;
  
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  
  .app__footer-cards {
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    margin: 2em 2rem 1rem;
  
    .app__footer-card {
      min-width: 290px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      svg {
        margin: 5px 10px;
        width: 30px;
        height: 30px;
      }
   
      padding: 1rem;
      border-radius: 5px;
      cursor: pointer;
  
      transition: all 0.3s ease-in-out;

      a {
        text-decoration: none;
        font-weight: 500;
        color: var(--black-color);
        font-size: 0.7rem;
      }
      &:hover {
        box-shadow: 0 0 25px #fef4f5;
      }
  
      @media screen and (max-width: 450px) {
        width: 100%;
      }
    }
  
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .contact-author {
    font-size: 10px;
    color: white;
    position: relative;
    margin-top: 50px;

    a {
      color: white;
    }
  }
  
  .app__footer-cards .app__footer-card:last-child {
    background-color: var(--white-color);
    width: 180px;
  }
  
  