.app__letter-container {
    width: 50%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 700px) {
        width: 100%;
    }

    .app__letter-inner {
        display: flex;
        flex-direction: column;
        width: 70%;
    }

    .app__letter-text {
        margin-top: 20px;
        color: white;
        text-align: justify;
        font-size: 0.8rem;

        @media screen and (max-width: 700px) {
            font-size: 0.7rem;
        }
    }

    img {
        width: 250px;
        right: 0%;
        left: 0;

        @media screen and (max-width: 700px) {
            width: 150px;
        }
    }
}