.app__about {
    flex: 1;
    width: 100%;
    flex-direction: column;
  }
  
  .app__profiles {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
  }
  
  .app__profile-item {
    width: 270px;
    min-height: 600px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 2rem;
    padding: 10px;
    border-radius: 15px;
  
    img {
      width: 100%;
      height: 150px;
      border-radius: 3px;
      object-fit: cover;
    }

    h2 {
      color: white;
    }

    p {
      color: white;
      text-align: justify;
      font-size: 0.7rem;
    }
  
    @media screen and (min-width: 2000px) {
      width: 370px;
      margin: 2rem 4rem;
  
      img {
        height: 320px;
      }
    }
  }