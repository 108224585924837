.app__testimonials {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.data {

  width: 100%;
  height: 80%;
  padding: 20px;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse ;
    height: auto;
  }

  .car_images {
    width: 70%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    @media screen and (max-width: 768px) {
      width: 100%;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr;
    }

    .image_container {
      width: 100%;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;


      @media screen and (max-width: 768px) {
        height: auto;
      }

      img {
        width: 100%;
        padding: 20px;
        transition: all;
        transition-duration: 0.5s;
      }

    }
  }

  .car_description {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    text-align: center;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    .title {
      display: flex;
      flex-direction: row;

      button {
        background-color: transparent;
        border: none;
        margin: 0px 20px;
        @media screen and (min-width: 768px) {
          display: none;
        }
      }

      h2 {
        font-weight: 100;
        text-align: center;
        text-decoration: underline;
        margin: 40px 0px;
  
        @media screen and (max-width: 768px) {
          margin: 10px 0px;
          font-size: 20px;
        }

        
      }
    }

    p {
      margin: 0px 40px;

      @media screen and (max-width: 768px) {
        margin: 10px 0px;
        font-size: 12px;
      }
    }

    a {
      height: 30px;
      width: 30px;
      margin-top: 40px;
      color: white;
      border: white 1px solid;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all;
      transition-duration: 0.5s;

      @media screen and (max-width: 768px) {
        margin: 20px 0px;
      }
    }

    a:hover {
      scale: 1.1;
    }
  }
}

.thumbnails {
  padding-top: 80px;
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }

  button {
    width: 15%;
    min-width: 80px;
    background-color: transparent;
    border: none;
    margin: 0px 10px;
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
  }

  img:hover {
    cursor: pointer;
    filter: grayscale(0%);
    transition: all;
    transition-duration: 0.5s;
  }

}