$debugMode: false;

.app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;

    padding: 6rem 2rem 0rem;

    @media screen and (min-width: 2000px) {
        padding-top: 8rem;
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }

    @media screen and (max-width: 450px) {
        padding: 6rem 1rem 2rem;
    }
}

.app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    margin: 0 2rem;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .video-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        height: 100%;
        z-index: 3;
        overflow: hidden;
        background-color: red;

        video {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }


    .app__header-info-text {
        width: 100%;
        z-index: 10;
        display: flex;
        flex-direction: column;
        color: var(--white-color);

        h1 {
            @if $debugMode ==true {
                background-color: blue;
            }

            display: flex;
            justify-content: right;
            font-size: 5vw;

            @media screen and (max-width: 768px) {
                justify-content: center;
            }
        }

        h3 {
            background-color: white;
            color: black;
            display: flex;
            justify-content: right;
            font-size: 2vw;
            padding-right: 5px;

            @media screen and (max-width: 768px) {
                justify-content: center;
                font-size: 3vw;
            }
        }

        h4 {
            @if $debugMode ==true {
                background-color: yellow;
            }

            display: flex;
            justify-content: right;
            font-size: 2vh;
        }
        
    }

    img {
        position: absolute;
        margin: 0;
        opacity: 0.2;
        width: 200%;

        @media screen and (max-width: 768px) {
            width: auto;
            height: 200%;
        }
    }

    video {
        position: absolute;
        width: 200%;
        object-fit: cover;
    }

    @media screen and (max-width: 1200px) {
        width: 100%;
        margin-right: 0rem;
    }
}

.app__header-badge {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    .badge-cmp,
    .tag-cmp {
        padding: 1rem 2rem;
        background: var(--white-color);
        border-radius: 15px;
        flex-direction: row;
        width: auto;

        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    }

    .tag-cmp {
        flex-direction: column;
        margin-top: 3rem;

        p {
            width: 100%;
            text-transform: uppercase;
            text-align: right;
        }
    }

    span {
        font-size: 2.5rem;

        @media screen and (min-width: 2000px) {
            font-size: 5rem;
        }
    }

    @media screen and (max-width: 1200px) {
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.app__header-circles {
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 1rem;

    div {
        width: 100px;
        height: 100px;
        border-radius: 50%;

        background: var(--white-color);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

        img {
            width: 60%;
            height: 60%;
        }
    }

    // circles with different height & width

    div:nth-child(1) {
        width: 100px;
        height: 100px;
    }

    div:nth-child(2) {
        margin: 1.75rem;
        width: 150px;
        height: 150px;
    }

    div:nth-child(3) {
        width: 70px;
        height: 70px;
    }

    @media screen and (min-width: 2000px) {
        div:nth-child(2) {
            width: 400px;
            height: 400px;
        }

        div:nth-child(3) {
            width: 170px;
            height: 170px;
        }

        div:nth-child(1) {
            width: 200px;
            height: 200px;
        }
    }

    @media screen and (max-width: 1200px) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;

        div {
            margin: 1rem;
        }
    }
}

.app__header-img {
    flex: 1;
    height: 100%;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;

    img {
        width: 100%;
        object-fit: contain;
        z-index: 1;
    }

    .overlay_circle {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        width: 100%;
        height: 90%;
    }

    @media screen and (max-width: 1200px) {
        margin: 2rem 0;
    }
}