.loader_container {
  height: 100vh;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 300px;

    @media screen and (max-width: 600px) {
      width: 150px;
      margin-bottom: 20px;
    }
  }
}
